/* You can add global styles to this file, and also import other style files */
:root {
    --primary-color: #1D91FC;
    --primary-color-hover: #6DB4FF;
    --primary-text: #66626E;
    --primary-text-light: #979DAC;
    --primary-gradient: linear-gradient(93.71deg, #1D91FC 0%, #0075FF 100%);

    --secondary-color-bg: #EBF0F9;
    --secondary-gradient: linear-gradient(92.62deg, #FF8C67 0%, #FF602E 100%);
    --secondary-text: #271947;

    --accent-orange: #FF8C67;

    --background-color: #F8F8F8;

    --error-text: #F96060;
    --success-color: #36CC60;

    --footer-bg: #EBF2FA;
    --white: #FFFFFF;
    --white-border: #EBEFF5;
    --white2: #F6F6FB;
    --white-grey: #FCFCFC;
    --light-grey: #E0E5EF;
    --disabled-grey: #ECEDF0;
    --light-gradient-contrast: linear-gradient(0deg, #FCFCFC, #FCFCFC);
    --white-gradient: linear-gradient(0deg, #FFFFFF, #D1E4FA);
    --white-gradient-fallback: #D1E4FA;

    --primary-button-text-color: var(--white);
    --primary-button-background: var(--primary-gradient);
    --primary-button-hover: var(--primary-color-hover);
    --primary-button-hover-action: rgb(109, 180, 255, 0.75);
    
    --secondary-button-text-color: var(--primary-color);
    --secondary-button-background: var(--white);
    --secondary-button-hover: var(--primary-color-hover);
    --secondary-button-border: var(--primary-color);

    --accent-button-text-color: var(--primary-color);
    --accent-button-background: #DCEEFE;
    --accent-button-hover: var(--primary-color-hover);
    
    --input-border: #ECEDF0;
    --input-border-hover: #EEEEEE;
    --input-background: #F3F6FC;

    --tab-bar-background: #F4F6FA;
    --tab-bar-disabled-text: #8A8A8A;

    --scrollbar-color: #CCCCCC;
}

@font-face {
    font-family: 'Outfit';
    src: url('assets/fonts/outfit/Outfit-Regular.ttf');
}

html, body {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
}

h1 {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 110%;
    color: var(--secondary-text);
}

h2 {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 160%;
    color: var(--secondary-text);
}

h3 {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 160%;
    color: var(--secondary-text);
}

.body-18 {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: normal;
    color: var(--primary-text);

    &--secondary {
        color: var(--secondary-text);
    }

    &--white {
        color: var(--white);
    }
}

.body-16 {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: var(--primary-text);

    &--secondary {
        color: var(--secondary-text);
    }

    &--white {
        color: var(--white);
    }
}

.body-14 {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: var(--primary-text);

    &--secondary {
        color: var(--secondary-text);
    }

    &--light {
        color: var(--primary-text-light);
    }

    &--error {
        color: var(--error-text);
    }
}

.body-12 {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    color: var(--primary-text-light);

    &--secondary {
        color: var(--secondary-text);
    }

    &--white {
        color: var(--white);
    }
}

.text {
    &--primary {
        color: var(--primary-text);
    }

    &--secondary {
        color: var(--secondary-text);
    }

    &--white {
        color: var(--white);
    }
}

.self-end {
    align-self: flex-end;
}

.scrollbar {
    &--overflow {
        overflow: auto;
    }

    &--light {
        &::-webkit-scrollbar-thumb {
            background: var(--disabled-grey) !important;
        }        
    }

    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-thumb {
        background: var(--scrollbar-color);
        border-radius: 30px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }
}

.tab-bar {
    &--active {
        color: var(--white);
        background-color: var(--primary-color);

        &:hover {
            color: var(--white);
            background-color: var(--primary-color);
        }
    }

    &--disabled {
        color: var(--tab-bar-disabled-text);
        background-color: transparent;

        &:hover {
            color: var(--white);
            background-color: var(--primary-color);
        }
    }
}

.divider {
    display: flex;
    align-self: center;
    width: 100%;
    height: 1px;
    background-color: var(--disabled-grey);

    &--p30 {
        width: calc(100% - 60px); // 30px on both sides, since we align ourselves in the center
    }
}

.flex {
    display: flex;
    
    &--100 {
        width: 100%;
        height: 100%;
    }
}

.w {
    &--100 {
        width: 100%;

        &_important {
            width: 100% !important;
        }
    }
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 160px;
    height: 40px;
    left: 27px;
    right: 26px;
    padding: 0px 12px;
    gap: 10px;
    border-radius: 10px;
    border: none;

    &--primary {
        color: var(--primary-button-text-color);
        background: var(--primary-button-background);
        box-shadow: 0px 2px 15px 0px rgba(29, 145, 252, 0.3);

        &:hover {
            background: var(--primary-button-hover);
            cursor: pointer;
        }

        &:active {
            color: var(--accent-button-text-color);
            background: var(--white);
            border: 1px solid var(--accent-button-hover);
            box-shadow: none;
        }

        &:disabled {
            color: var(--primary-text-light) !important;
            background: var(--disabled-grey) !important;
            border: none !important;
            box-shadow: none !important;
            cursor: default !important;
        }

        &:focus-visible {
            outline: none;
        }
    }

    &--secondary {
        color: var(--secondary-button-text-color);
        background: var(--secondary-button-background);
        border: 1px solid var(--secondary-button-border);
        box-shadow: none;

        &:hover {
            background: var(--secondary-button-hover);
            color: var(--white);
            box-shadow: 0px 2px 15px 0px rgba(29, 145, 252, 0.3);
            border: none;
            cursor: pointer;
        }

        &:active {
            background: var(--primary-button-background);
        }

        &:focus-visible {
            outline: none;
        }
    }

    &--accent {
        color: var(--accent-button-text-color);
        background: var(--accent-button-background);
        
        &:hover {
            background: var(--white);
            border: 1px solid var(--accent-button-hover);
            color: var(--accent-button-hover);
            box-shadow: none;
            cursor: pointer;
        }

        &:focus-visible {
            outline: none;
        }
    }

    span {
        font-family: 'Outfit';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 130%;
    }

    fa-icon {
        font-size: 15px;
    }
}

button.loading::after {
    content: "";
    position: relative;
    width: 16px;
    height: 16px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border: 2px solid transparent;
    border-top-color: #ffffff;
    border-right-color: #ffffff;
    border-bottom-color: #ffffff;
    border-radius: 50%;
    animation: button-loading-spinner 1s ease infinite;

    @keyframes button-loading-spinner {
        from {
            transform: rotate(0turn);
        }
    
        to {
            transform: rotate(1turn);
        }
    }
}

.loading::after {
    content: "";
    position: relative;
    width: 32px;
    height: 32px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border: 2px solid transparent;
    border-top-color: var(--primary-color);
    border-right-color: var(--primary-color);
    border-bottom-color: var(--primary-color);
    border-radius: 50%;
    animation: button-loading-spinner 1s ease infinite;

    @keyframes button-loading-spinner {
        from {
            transform: rotate(0turn);
        }
    
        to {
            transform: rotate(1turn);
        }
    }
}

.text-button {
    &--primary {
        font-family: 'Outfit';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 150%;
        color: var(--primary-color);
        user-select: none;
        
        &:hover {
            color: var(--primary-color-hover);
            cursor: pointer;
        }

        &:active {
            color: var(--primary-color);
        }
    }
}

.circle-background {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
}

@media only screen and (max-width: 499px) {
    .circle-background {
        top: 0;
    }
}

.total-points-circle-background {
    width: 100%;
    height: 83px;
    position: absolute;
    overflow: hidden;
}

.coming-soon-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
    border-radius: 30px;
    background-color: rgba(255, 255, 255, 0.75);    
}

/* ---- Utility ---- */
.mt-12 {
    margin-top: 12px;
}

.mt-24 {
    margin-top: 24px;
}

.mb-12 {
    margin-bottom: 12px;
}

.mb-24 {
    margin-bottom: 24px;
}

.bold {
    font-weight: 600;
}

.z-index {
    &--1 {
        z-index: 1;
    }
}

.bg {
    &--white-grey {
        background-color: var(--white-grey);
    }
}

.color {
    &--accent-orange {
        color: var(--accent-orange);
    }
}

.error {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: var(--error-text);
    padding-top: 4px;

    &--checkbox {
        font-size: 10px;
        line-height: 120%;
    }
}

/* ---- Material ---- */
.draftzy-form .mat-mdc-form-field-flex .mdc-notched-outline .mdc-notched-outline__trailing {
    border-radius: 0 10px 10px 0;
}

.draftzy-form .mat-mdc-form-field-flex .mdc-notched-outline .mdc-notched-outline__leading {
    border-radius: 10px 0 0 10px;
    width: 10px !important;
}

.draftzy-form .mat-mdc-form-field-flex {
    display: inline-flex;
    align-items: center;
    width: 100px;
    height: 32px;
    gap: 14px;
    padding: 4px 8px 4px 12px;
}

.draftzy-form .mat-mdc-form-field-flex .mdc-notched-outline .mdc-notched-outline__notch label {
    top: 50%;
}

.draftzy-form .mat-mdc-form-field-flex .mdc-notched-outline .mdc-notched-outline__notch label.mdc-floating-label--float-above {
    top: 28px;
}